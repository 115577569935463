import { Observable, throwError } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, map } from 'rxjs/operators';
import { CartState, cartStore } from './cart.store';
import { cartQuery } from './cart.query';
import { toast } from 'react-toastify';
import { anonCartId, authRequestHeaders, fetchConfig, genericRequestHeaders, setLocalStorageItem } from '../utils';

export const selectCart$: Observable<CartState> = cartQuery.select();

export const getCustomerCart = async () => {
  await ajax({
    url: '/api/cart',
    method: 'GET',
    headers: authRequestHeaders(),
  })
    .pipe(
      map(({ response }) => {
        cartStore.update((state) => {
          return {
            ...state,
            ...response
          }
        });
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    )
    .toPromise()
    .catch((err) => {
      console.log('getCustomerCart', err);
    });
};

export const getAnonCart = async (anonCartId: number = 0) => {
  const url = anonCartId > 0 ? `/api/anon-cart/${anonCartId}` : `/api/anon-cart`;

  await ajax({
    url: url,
    method: 'GET',
    headers: genericRequestHeaders(),
  })
    .pipe(
      map(({ response }) => {
        setLocalStorageItem('uStoreAnonCartId', response.id);
        cartStore.update((state) => {
          return {
            ...state,
            ...response
          }
        });
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    )
    .toPromise()
    .catch((err) => {
      console.log('getAnonCart err', err);
    });
};

export const addItemToCart = async (product_id, variation_id, qty, price) => {
  const data = {
    product_id: +product_id,
    variation_id: variation_id === null ? null : +variation_id,
    qty: +qty,
    price: +price,
  };
  const config = fetchConfig('/api/cart/products', `/api/anon-cart/products/${anonCartId()}`);

  await ajax({
    url: config.url,
    method: 'POST',
    headers: config.headers,
    body: data,
  })
    .pipe(
      map(({ response }) => {
        cartStore.update((state) => {
          return {
            ...state,
            ...response
          }
        });
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    )
    .toPromise()
    .catch((err) => {
      if (err?.response?.message === "Purchase Limit Exceeded") {
        toast.error('Purchase Limit Exceeded', {
          position: 'bottom-center',
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      }
    });
};

export const removeItem = async (id) => {
  const config = fetchConfig(
    `/api/cart/products/${id}`,
    `/api/anon-cart/products/${anonCartId()}/${id}`
  );

  await ajax({
    url: config.url,
    method: 'DELETE',
    headers: config.headers,
  })
    .pipe(
      map(({ response }) => {
        cartStore.update((state) => {
          return {
            ...state,
            ...response
          }
        });
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    )
    .toPromise()
    .catch((err) => {
      console.log('removeItem err', err);
    });
};

export const decrementQty = async (id) => {
  const config = fetchConfig(
    `/api/cart/products/${id}/decrement_qty`,
    `/api/anon-cart/products/${anonCartId()}/${id}/decrement_qty`
  );

  await ajax({
    url: config.url,
    method: 'PUT',
    headers: config.headers,
  })
    .pipe(
      map(({ response }) => {
        cartStore.update((state) => {
          return {
            ...state,
            ...response
          }
        });
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    )
    .toPromise()
    .catch((err) => {
      console.log('decrementQty err', err);
    });
};

export const incrementQty = async (id) => {
  const config = fetchConfig(
    `/api/cart/products/${id}/increment_qty`,
    `/api/anon-cart/products/${anonCartId()}/${id}/increment_qty`
  );

  await ajax({
    url: config.url,
    method: 'PUT',
    headers: config.headers,
  })
    .pipe(
      map(({ response }) => {
        cartStore.update((state) => {
          return {
            ...state,
            ...response
          }
        });
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    )
    .toPromise()
    .catch((err) => {
      if (err?.response?.message === "Purchase Limit Exceeded") {
        toast.error('Purchase Limit Exceeded', {
          position: 'bottom-center',
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      }
    });
};

export const clearCart = async () => {
  const config = fetchConfig(`/api/cart/clear`, `/api/anon-cart/clear/${anonCartId()}`);

  await ajax({
    url: config.url,
    method: 'POST',
    headers: config.headers,
  })
    .pipe(
      map(({ response }) => {
        cartStore.update((state) => {
          return {
            ...state,
            ...response
          }
        });
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    )
    .toPromise()
    .catch((err) => {
      console.log('clearCart err', err);
    });
};

export const applyCoupon = (coupon_code) => {
  return ajax({
    url: '/api/cart/apply-coupon',
    method: 'POST',
    headers: authRequestHeaders(),
    body: {
      coupon_code
    },
  }).pipe(
    catchError((error) => {
      return throwError(error);
    })
  );
};


export const getMinTotalRequirement = async () => {
  await ajax({
    url: '/api/cart/min-total',
    method: 'GET',
    headers: authRequestHeaders(),
  })
    .pipe(
      map(({ response }) => {
        cartStore.update((state) => {
          return {
            ...state,
            ...response
          }
        });
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    )
    .toPromise()
    .catch((err) => {
      console.log('getMinTotalRequirement', err);
    });
};
