import { PaymentCard, UserAddress, UserState, userStore } from './user.store';
import { userQuery } from './user.query';
import { Observable, of, throwError } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { getCustomerCart } from '../cart/cart.service';
import { catchError, concatMap, filter, map, switchMap, tap } from 'rxjs/operators';
import {
  anonCartId,
  authRequestHeaders,
  genericRequestHeaders,
  getLocalStorageItem,
} from '../utils';
import { checkoutQuery } from '../checkout/checkout.query';
import { navigate } from 'gatsby-link';

export const setUser = (user): void => {
  userStore.update(user);
};

export const selectUser$: Observable<UserState> = userQuery.select();

export const selectUserPaymentCards$: Observable<PaymentCard[]> = userQuery.select().pipe(
  switchMap((user) => {
    return of(user.payment_cards);
  }),
  map((cards) => {
    let defaultCard = null;
    const orderedCards = cards.filter(card => {
      if (card.is_primary) {
        defaultCard = card;
      }
      return !card.is_primary;
    }).sort((a, b) => <any>new Date(b.created_at) - <any>new Date(a.created_at));

    if (defaultCard) {
      orderedCards.unshift(defaultCard);
    }

    return orderedCards;
  })
);

export const selectUserAddresses$: Observable<UserAddress[]> = userQuery.select().pipe(
  switchMap((user) => {
    return of(user.addresses);
  }),
);

export const selectCheckoutAddress$: Observable<UserAddress> = checkoutQuery
  .select('address_id')
  .pipe(
    switchMap((address_id) => {
      const localAddressId = getLocalStorageItem('uStoreCheckoutAddressId');
      if (address_id) {
        return of(address_id);
      } else if (!address_id && localAddressId) {
        return of(localAddressId);
      } else if (!address_id && !localAddressId) {
        navigate('/checkout/')
        return of(null);
      }
    }),
    switchMap((address_id) => {
      return userQuery.select('addresses').pipe(
        switchMap((addresses) => {
          return of(addresses.find((address) => address.id === +address_id));
        })
      );
    })
  );

export const selectCheckoutCard$: Observable<PaymentCard> = checkoutQuery
  .select('payment_card_id')
  .pipe(
    switchMap((payment_card_id) => {
      if (payment_card_id) {
        return of(payment_card_id);
      }
      return of(getLocalStorageItem('uStoreCheckoutCardId'));
    }),
    switchMap((payment_card_id) => {
      if (!payment_card_id) {
        return selectDefaultCard$;
      }
      return userQuery.select('payment_cards').pipe(
        switchMap((cards) => {
          return of(cards.find((card) => card.id === +payment_card_id));
        })
      );
    })
  );

export const selectDefaultCard$: Observable<PaymentCard> = userQuery.select('payment_cards').pipe(
  filter((payment_cards) => !!payment_cards),
  switchMap((cards) => {
    return of(cards.find((card) => card.is_primary));
  })
);

export const getUser = userQuery.getValue();

export const registerUser = (email) => {
  return ajax({
    url: '/api/store/register',
    method: 'POST',
    headers: genericRequestHeaders(),
    body: {
      email,
      tenant_id: parseInt(process.env.GATSBY_USTORE_TENANT_ID),
      domain: window.location.origin,
    },
  }).pipe(
    map(({ response }) => {
      return response;
    }),
    catchError((error) => {
      return throwError(error);
    })
  );
};

export const loginUser = (email, password) => {
  return ajax({
    url: '/api/store/login',
    method: 'POST',
    headers: genericRequestHeaders(),
    body: {
      email,
      password,
    },
  }).pipe(
    map(({ response }) => {
      return response;
    }),
    catchError((error) => {
      return throwError(error);
    })
  );
};

export const verifyOtp = (otp, token) => {
  return ajax({
    url: '/api/store/verifyotp',
    method: 'POST',
    headers: genericRequestHeaders(),
    body: {
      otp,
      token,
    },
  }).pipe(
    map(({ response }) => {
      return response;
    }),
    catchError((error) => {
      return throwError(error);
    })
  );
};

export const setPassword = (password, confirmedPassword, uuid) => {
  return ajax({
    url: '/api/store/setpassword',
    method: 'POST',
    headers: genericRequestHeaders(),
    body: {
      password,
      confirmedPassword,
      uuid,
    },
  }).pipe(
    map(({ response }) => {
      return response;
    }),
    catchError((error) => {
      return throwError(error);
    })
  );
};

export const changeLoginPassword = (currentPassword, newPassword, confirmedPassword) => {
  return ajax({
    url: '/api/store/change-password',
    method: 'POST',
    headers: authRequestHeaders(),
    body: {
      currentPassword,
      newPassword,
      confirmedPassword,
      domain: window.location.origin,
    },
  }).pipe(
    map(({ response }) => {
      return response;
    }),
    catchError((error) => {
      return throwError(error);
    })
  );
};

export const requestResetPassword = (email) => {
  return ajax({
    url: '/api/store/password/forgot',
    method: 'POST',
    headers: genericRequestHeaders(),
    body: {
      email: email.toLowerCase(),
      domain: window.location.origin,
    },
  }).pipe(
    map(({ response }) => {
      return response;
    }),
    catchError((error) => {
      return throwError(error);
    })
  );
};

export const resetPassword = (password, confirmedPassword, token) => {
  return ajax({
    url: '/api/store/password/reset',
    method: 'POST',
    headers: genericRequestHeaders(),
    body: {
      password,
      confirmedPassword,
      token,
    },
  }).pipe(
    map(({ response }) => {
      return response;
    }),
    catchError((error) => {
      return throwError(error);
    })
  );
};

export const getCustomerProfile = async () => {
  return await ajax({
    url: '/api/customer/profile',
    method: 'GET',
    headers: authRequestHeaders(),
  })
    .pipe(
      map(({ response }) => {
        setUser(response);
        getCustomerCart();
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    )
    .toPromise()
    .catch(async err => {
      if (err?.statusCode === 500 || err?.response?.statusCode == 401) {
        await logout();
      }
    });
};

export const mergeCustomerAndAnonCart = async () => {
  await ajax({
    url: `/api/cart/merge-anon-cart/${anonCartId()}`,
    method: 'GET',
    headers: authRequestHeaders(),
  })
    .pipe(
      catchError((error) => {
        return throwError(error);
      })
    )
    .toPromise()
    .catch((err) => {
      console.log('mergeCustomerAndAnonCart err', err);
    });
};

export const updateProfile = (data): Observable<any> => {
  return ajax({
    url: `/api/customer/profile`,
    method: 'PUT',
    headers: authRequestHeaders(),
    body: data,
  }).pipe(
    map(({ response }) => response),
    tap(() => {
      userStore.update({
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        birth_date: data.birth_date,
        phone: data.phone,
      });
    }),
    catchError((error) => {
      return throwError(error);
    })
  );
}

export const completeProfile = (data): Observable<any> => {
  const profileData = {
    first_name: data.first_name,
    last_name: data.last_name,
    email: data.email,
    birth_date: data.birth_date,
    phone: data.phone,
  };

  const updateProfile$ = ajax({
    url: `/api/customer/profile`,
    method: 'PUT',
    headers: authRequestHeaders(),
    body: profileData,
  }).pipe(
    map(({ response }) => response),
    catchError((error) => {
      return throwError(error);
    })
  );

  const addAddress$ = addCustomerAddress(data);

  return updateProfile$.pipe(
    concatMap(() => {
      return addAddress$;
    })
  );
};

export const addCustomerAddress = (data): Observable<any> => {
  const addressData = {
    address_line_1: data.address_line_1,
    city: data.city,
    postal_code: data.postal_code,
    state: data.state,
    country: data.country,
    label: data.label,
  };

  return ajax({
    url: `/api/customer/address`,
    method: 'POST',
    headers: authRequestHeaders(),
    body: addressData,
  }).pipe(
    map(({ response }) => response),
    catchError((error) => {
      return throwError(error);
    })
  );
};

export const updateCustomerAddress = (id, data): Observable<any> => {
  const addressData = {
    address_line_1: data.address_line_1,
    city: data.city,
    postal_code: data.postal_code,
    state: data.state,
    country: data.country,
    label: data.label,
  };

  return ajax({
    url: `/api/customer/address/${id}`,
    method: 'PUT',
    headers: authRequestHeaders(),
    body: addressData,
  }).pipe(
    map(({ response }) => response),
    catchError((error) => {
      return throwError(error);
    })
  );
};

export const deleteCustomerAddress = (id): Observable<any> => {
  return ajax({
    url: `/api/customer/address/${id}`,
    method: 'DELETE',
    headers: authRequestHeaders(),
  }).pipe(
    map(({ response }) => response),
    catchError((error) => {
      return throwError(error);
    })
  );
};

export const getCustomerOrders = (): Observable<any> => {
  return ajax({
    url: `/api/customer/orders`,
    method: 'GET',
    headers: authRequestHeaders(),
  }).pipe(
    map(({ response }) => response),
    catchError((error) => {
      return throwError(error);
    })
  );
};

export const searchPostcodes = (value): Observable<any> => {
  return ajax({
    url: `/api/postcodes?search=${value}&store_id=${process.env.GATSBY_USTORE_TENANT_ID}`,
    method: 'GET',
    headers: genericRequestHeaders(),
  }).pipe(
    map(({ response }) => {
      return response.map((item) => ({
        id: item.id,
        name: `${item.postcode} - ${item.place_name} - ${item.state_code}`,
        serviced: item.serviced,
        delivery_fee: item.delivery_fee,
        est_del_time: item.est_del_time,
      }));
    }),
    catchError((error) => {
      return throwError(error);
    })
  );
};


export const addToWaitList = (email, pdata) => {
  return ajax({
    url: '/api/store/wait-list',
    method: 'POST',
    headers: genericRequestHeaders(),
    body: {
      email,
      pdata
    },
  }).pipe(
    map(({ response }) => {
      return response;
    }),
    catchError((error) => {
      return throwError(error);
    })
  );
};

export const logout = async () => {
  return await navigate('/logout/');
};
