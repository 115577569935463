import { Store } from "@datorama/akita"

export interface CartState {
  id: number;
  products: any[];
  subtotal: number;
  min_cart_total: number;
}

export class CartStore extends Store<CartState> {
  constructor() {
    super({}, { name: "cart", resettable: true })
  }
}

export const cartStore = new CartStore()
