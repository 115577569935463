import { Store } from "@datorama/akita"

export interface Postcode {
  id: number;
  name: string;
}

export interface CheckoutState {
  address_id: number;
  payment_card_id: number;
  delivery_location_id: number;
  delivery_due_date: any;
  special_instructions: string;
  accepted_by: string;
  postcode: Postcode
}

export class CheckoutStore extends Store<CheckoutState> {
  constructor() {
    super({}, { name: "checkout", resettable: true })
  }
}

export const checkoutStore = new CheckoutStore()
